<script lang="ts">
    import type { Stream } from '../../interfaces/types';

    export let streams: Stream[] = [];
    export let showLiveIndicators = true;
    export let showUsername = true;
</script>

<div class="streams-grid">
    {#if streams && streams.length > 0}
        {#each streams as stream (stream.id)}
            <div class="stream-item">
                <a href="/stream/{stream.id}" class="stream-link">
                    <div class="stream-thumbnail">
                        <img src="{stream.thumbnail}" alt="{stream.title}" />
                        {#if showLiveIndicators && stream.is_live}
                            <span class="live-badge">LIVE</span>
                        {/if}
                    </div>
                    <div class="stream-info">
                        <h3 class="stream-title">{stream.title}</h3>
                        {#if showUsername}
                            <div class="stream-username">@{stream.user.username}</div>
                        {/if}
                    </div>
                </a>
            </div>
        {/each}
    {:else}
        <div class="empty-streams">
            <p>No streams available</p>
        </div>
    {/if}
</div>

<style>
    .streams-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 1rem;
    }

    .stream-item {
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    .stream-link {
        display: block;
        color: inherit;
        text-decoration: none;
    }

    .stream-thumbnail {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 aspect ratio */
    }

    .stream-thumbnail img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .live-badge {
        position: absolute;
        top: 8px;
        left: 8px;
        background: red;
        color: white;
        padding: 2px 8px;
        border-radius: 4px;
        font-size: 0.75rem;
        font-weight: bold;
    }

    .stream-info {
        padding: 0.75rem;
    }

    .stream-title {
        margin: 0;
        font-size: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .stream-username {
        margin-top: 4px;
        color: #666;
        font-size: 0.875rem;
    }

    .empty-streams {
        grid-column: 1 / -1;
        padding: 2rem;
        text-align: center;
        color: #666;
    }
</style>
